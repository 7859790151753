<template>
  <div v-loading="logining" class="login-container">
    <el-form :model="form" :rules="rules" status-icon ref="form" class="login-page">
      <h3 class="title">乐兔图书馆后台登录</h3>
      <el-form-item prop="account">
        <el-input type="text" v-model="form.account" auto-complete="off" placeholder="用户名" clearable></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="form.password" auto-complete="off" placeholder="密码" show-password clearable
          @keypress.enter.native="handleSubmit"></el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="rememberme" class="rememberme">记住密码</el-checkbox> -->
      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="handleSubmit">登录</el-button>
      </el-form-item>
    </el-form>
    <!-- 选择图书馆 -->
    <modal :is-visible="isVisible" @close="closeModal">
      <template v-slot:header>
        <h3>请选择您要进入的图书馆</h3>
      </template>
      <div class="listLibrary">
        <div class="square" v-for="item in libraryList" :key="item.index" @click="loginLibrary(item)">
          <p> {{ item.branchName }}</p>
          <p>{{ item.branchPosition }}</p>
        </div>
      </div>
      <template v-slot:footer>
        <el-button plain @click="closeModal">取消</el-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { login } from '@/api/login'
import { setRole, setToken, getToken, setUserName } from '@/utils/auth'
import { listLibrary, selectLibrary } from '@/api/library'

export default {
  data() {
    return {
      // 选择图书馆
      isVisible: false,
      libraryList: [],
      // 按钮加载
      logining: false,
      // 表单属性
      form: {
        account: undefined,
        password: undefined,
      },
      // 表单校验规则
      rules: {
        account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
      // rememberme: false,
    }
  },
  methods: {
    // 表单提交
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.logining = true
          login(this.form).then(res => {
            if (res.code === '200') {
              //console.log(res.data.username+"888888")
              setToken(res.data.token) // 保存token到session中
              setRole(res.data.role) // 设置用户角色
              setUserName(JSON.stringify(res.data.username))
              this.$router.push({ path: '/' })
              // }
            } else {
              this.$alert(res.message, '信息', {
                confirmButtonText: '好的'
              })
            }
          })
        }
        this.logining = false
      })
    },
    // 获取分馆信息
    libraryLists() {
      listLibrary().then(({ data }) => {
        this.libraryList = data.libraryBranchData
      })
    },
    // 进入分馆
    loginLibrary(item) {
      const parameter = { aid: item.aid, bid: item.bid }
      selectLibrary(parameter).then(({ data }) => {
        setToken(data)
        this.isVisible = false;//关闭弹框
        this.$router.push({ path: '/' })
      })
    },
    // 取消
    closeModal() {
      this.isVisible = false;
    },
  }
}
</script>

<style scoped>
/* CSS Reset */
.login-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url('../../assets/img/background.svg');
  background-size: contain;
}

.login-page {
  border-radius: 5px;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>